import React, { Component } from 'react';
import Helmet from 'react-helmet';

class GooPro extends Component {

    state = {
        'currSeo': this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname).length !== 0 ?
            this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname)[0] :
            this.props.seoLinks[0]
    }

    componentDidMount() {
        let urlToRedirect = this.state.currSeo.urlToSend ? this.state.currSeo.urlToSend : 'https://textcaseconvert.com';
        setTimeout(() => document.getElementById('wait').innerText = 'connecting...', 1500);
        setTimeout(() => document.getElementById('wait').innerText = 'loading...', 2700);
        setTimeout(() => document.getElementById('wait').innerText = 'wait...', 3800);
        setTimeout(() => window.location = urlToRedirect, 8000); // max to wait

        // check when ads as printed to
        // var observer = new MutationObserver(function (mutations) {
        //     if (document.getElementById('p_7967215')) {
        //         //console.log("It's in the DOM!");
        //         clearTimeout(t1);
        //         clearTimeout(t2);
        //         clearTimeout(t3);
        //         clearTimeout(tDef);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait.', 1500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait...', 2600);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait.....', 4500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait........', 6500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait...........', 7500);
        //         setTimeout(() => window.location = urlToRedirect, 7500);
        //         observer.disconnect();
        //     }
        // });
        // observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
    }

    render() {
        const ldJson = {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Tool to Upper to Lower Case - Capital - Easy to Use",
            "applicationCategory": "BrowserApplication",
            "browserRequirements": "Requires JavaScript. Requires HTML5.",
            "operatingSystem": "All",
            "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
            },
            "url": "https://www.goo.pro",
            "keywords": "capital,upper,lower,case converter",
            "description": "A free tool for text manipulation - Simple and easy with many options."
        }


        ldJson.name = this.state.currSeo.title ? this.state.currSeo.title : "Capital Text Case Tool - Easy to Use"
        ldJson.description = this.state.currSeo.description ? this.state.currSeo.description : "A free tool for text manipulation - Simple and easy with many options."
        ldJson.url = 'https://www.goo.pro' + this.props.location.pathname;

        return (
            <React.Fragment>
                <Helmet>
                    <html lang={this.props.location.pathname.split('/')[2] ? this.props.location.pathname.split('/')[2] : 'en'} />
                    <title>{ldJson.name}</title>
                    <meta name="description" content={ldJson.description} />
                    <meta property="og:description" content={ldJson.description} />
                    <meta property="og:image" content="https://www.goo.pro/goo512.jpg" />
                    <meta name="author" content="goo.pro" />
                    <link rel="icon" href="/gooFavicon.ico" />
                    <meta name="keywords" content={ldJson.keywords} />
                    {/* <meta name="monetag" content="682c8a681b6668f508823caa50d0edb1"></meta> */}
                    {/* <link rel="preconnect" href="https://reetahoo.com" /> */}
                    {/* <link rel="dns-prefetch" href="https://reetahoo.com" /> */}
                    {/* <script async="async" data-cfasync="false" src="https://reetahoo.com/401/8510145"></script> */}
                    {/* <link rel="preload" href="https://reetahoo.com/401/8510145" as="script" /> */}
                    {/*<link rel="preload" href="https://www.googletagmanager.com/gtag/js?id=G-SCKD1LJSWG" as="script" /> */}
                    <link rel="canonical" href={ldJson.url} />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-SCKD1LJSWG"></script>
                    <script>
                        {`var host = window.location.hostname;
                        if (host != "localhost") {
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('config', 'G-SCKD1LJSWG');
                        }`}
                    </script> */}
                </Helmet>
                <img src="/spinner.gif" alt="loading" width="20" height="20" /><div id="wait" style={{ 'color': '#000' }}></div>
                <ul>
                    {this.props.seoLinks.filter(seo => seo.domain === 'goo.pro').map((seo, idx) =>
                        <li key={idx}><a href={`${seo.path}`} title={seo.anchorText}>{seo.anchorText}</a></li>)}
                </ul>

                <h1>{this.state.currSeo.anchorText}</h1>
                <h2>Great tool for text manipulation</h2>
                <p>
                    {this.state.currSeo.textBody ?
                        this.state.currSeo.textBody :
                        `Great tool for text manipulation, very useful for various applications. It's always like this, when we need something we never have one nearby, which ends up being very frustrating at times.`
                        + ` Who doesn't need or needed a ${this.state.currSeo.anchorText} at some point?! `
                        + `Wow... Now it exists! ${this.state.currSeo.anchorText} The best and most complete ${this.state.currSeo.anchorText} is simple, fast and easy to use, always available when you need it.`
                    }
                </p>
            </React.Fragment>
        )
    }


}

export default GooPro;